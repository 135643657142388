.image {
  height: 350px;
  width: 350px;
  border-radius: 150px;
}

.title {
  padding: 10px 0px;
    position: relative;
  font-size: 30px;
  font-weight: 700;
  margin-top: 10px;
  color: #fff;
  transition: all ease-in-out .3s;
  transform-origin: left;

}
.title:hover{
    text-transform: uppercase;
}
.title::before{
    content: '';
    position: absolute;
    background-color: #62CDFF;
    top: 0;
    left: 0;
    bottom: 0;
    right:0;
    width: 5%;
    z-index: -1;
    transition: all ease-in-out .3s;

}
.title:hover::before{
 width: 100%;
}



.para {
  background-color: red;
  display: inline;
  padding: 5px 25px;
  color: white;
  font-size: 16px;
}

.wrapper {
  margin: 20px 20px;
  margin-top: 100px;
  cursor: pointer;
  padding: 20px 0px;
  border-radius: 30px;
  background: rgba(140, 4, 244, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.6px);
  -webkit-backdrop-filter: blur(8.6px);
  border: 1px solid rgba(4, 104, 244, 0.21);
}

@media  screen and (max-width:700px) {
  .image {
    height: 200px;
    width: 200px;
    border-radius: 150px;
  }
  
}
@media screen and (min-width:700) and (max-width:1000px) {
  .image{
    height: 250px;
    width: 250px;
  }
}