@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

/* .container{
    padding: 0 60px;
} */
.motive{
    border-top: white 3px solid;
    /* background-color: #efe9dc; */
    padding: 60px 30px;

   
}
.speech{
    font-size: 20px;
    cursor: pointer;
    font-family: 'Roboto Condensed', sans-serif;
    padding: 0 60px;
    text-align: justify;
    opacity: .75;
}
.speech:hover{
    box-shadow: 20px 30px 30px 15px rgba(176, 170, 170, 0.316);
}

@media screen and (max-width:700px){
    .speech{
        font-size: 16px;
        padding: 0px 10px;
        text-align: justify;
    }
    .motive{
        padding: 30px 15px;
    }

}