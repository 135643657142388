.cards{
    display: grid;
    margin-top: 100px;
    margin-bottom: 100px;
    grid-template-columns: repeat(3,2fr);
    align-items: center;
    /* justify-content: center; */
    justify-items: center;
    grid-row-gap: 50px;
   
}
.head{
    margin-top: 50px;
    font-size: 50px;
    letter-spacing: 5px;
}

@media screen and (max-width:700px) {
    .cards{
        grid-template-columns: 1fr;
    }
}