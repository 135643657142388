.mainFrame{
    height: 600px;
}
.caption{
    background-color: rgba(0, 0, 0, 0.437);
}

@media only screen and (max-width: 700px) {
    .mainFrame{
        height: 280px;
    }
   }
 