@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.logo{

color: white;
text-decoration: none;
font-size: 30px;

}
.logo:hover{
    color: white;
}
.item{
    text-decoration: none;
    color: white;
    font-size: 20px;
   padding: 0 30px; 
}
.item:hover{
    color:#AA77FF;
    
}
.logoImg{
    border-radius: 60%;
    width: 100px;
    height: 100px;
    margin-right: 10px;

}

@media screen and  (max-width:700px) {
    .logo{
        font-size: 10px;
    }
    .logoImg{
        width: 30px;
        height: 30px;
    }
    .item{
        
        font-size: 10px;
       padding: 0 5px; 
    }
}
@media screen and (min-width:700px) and (max-width:1000px) {
    
  }