
.container{

    height: 300px;
    background-color: #000;
    display: grid;
    grid-template-columns: 1fr 3fr;
    color: #fff;
}
.image{
    margin-top: 50px;
    height: 150px;
    width: 150px;
    border-radius: 60%;
}
.right{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 30px;
    
}
.name{
    font-size: 20px;
    margin-top: 20px;
}

.item{
    text-align:left ;
    margin-top: 10px;
    cursor: pointer;
}
.head{
    text-align: left;
    font-size: 25px;
    letter-spacing: 5px;
    color: white;
cursor: pointer;
    text-decoration: none;
}
.link{
    text-decoration: none;
    color: #fff;
}
.link:hover{
    color: #fff;
}
.social{
    
    display: flex;
    margin-top: 40px;
    align-items: flex-start;

}
.socialItem{
    padding: 5px 15px;
    font-size: 50px;
}

.copy{
    color: rgb(37, 36, 36);
    display: grid;
    grid-template-columns: 3fr;
}
.copyItem{
    position: relative;
    color: #ffffff6c;
}
.checkMails{
    font-size: 20px;
    margin-top: 30px;
}
@media  screen and (max-width:700px) {
    .container{

        height: 1100px;
        
        display: grid;
        grid-template-columns: 1fr;
        color: #fff;
    }
    .right{
        display: grid;
        grid-template-columns: 1fr ;
        margin-top: 30px;
        margin-left: 20%;
        
    }
}
@media screen and (min-width:200px) and (max-width:550px){
    .right{
        margin-left:20%;
    }
}