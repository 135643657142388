.container{
    display: grid;
    grid-template-columns: repeat(3,2fr);
    justify-items: center;
    margin-top: 50px;
    margin-bottom: 40px;
}
.gallery{
    margin: 50px 10px ;
    font-size: 50px;
    font-weight: 700;
}
@media  screen and (max-width:700px) {
    .container{
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
    }
}