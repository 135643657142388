@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-items: center;
  background: linear-gradient(to right, #a2ea4c 200px, #07aa43 200px, #f7f7f7 );
  /* background-color: rgb(255, 0, 0); */
  /* border-top: 2px solid rgb(9, 67, 192);
    border-bottom: 2px solid rgb(9, 67, 192); */
}
.right {

  margin-left: 60px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.image {
 
  width: 500px;
  height: 500px;
  border-radius: 60%;
  border: 10px rgb(9, 67, 192) solid;
  

}
.detail {
  text-align: left;
}

.inner {
  padding-left: 60px;
  padding-right: 60px;
  text-align: left;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 3px;
  font-size: 30px;
}
.main {
  font-family: "Roboto Mono", monospace;
  position: relative;
  padding-left: 60px;
  top: -60px;
  font-size: 60px;
  border-bottom: 5px solid rgb(9, 67, 192);
  transition: border ease-in-out 30ms;
  cursor: pointer;
}
.presidentPara{
  font-family: "Open Sans", sans-serif;
  font-weight: 700px;
  text-align: center;
  font-size: 30px;
 
}

@media screen and (max-width:700px){
  .container{
    grid-template-columns: 1fr ;
    align-items: center;
  }
  .right {

    margin-left: 10px;
    ;
  }
  .image {
    width: 100px;
    height: 100px;
   
    border: 3px rgb(9, 67, 192) solid;
  }
  .main {
    
    position: relative;
    text-align: center;
    padding-left: 30px;
    top: -1px;
    font-size: 20px;
    border-bottom: 0px solid rgb(9, 67, 192);
  }
  .presidentPara{
    font-weight: 700px;
  text-align: center;
  margin-left: 60px;
  
  font-size: 10px;
  
  }
  .inner {
    padding-left: 10px;
    padding-right: 90px;
    text-align: left;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 3px;
    font-size: 30px;
  }
}