.container{
    display: grid;
    height: 250px;
    padding: 5px 60px;
    background-color: #faedcd9f;
    grid-template-columns: repeat(4,2fr);
    align-items: center;
}

@media screen and (max-width:700px) {
    .container{
        grid-template-columns: 1fr;
        height: 900px;
    }
}